import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
// import { EffectFade, Navigation, Pagination } from 'swiper/modules';
// Swiper.use([EffectFade, Navigation, Pagination]);
import 'magnific-popup';


jQuery(document).ready(function ($) {
    


    // home tabs
    $('.tabs li').click(function () {
        $(this).addClass('active').siblings().removeClass('active');
        $('.fading-hero').removeClass('active').eq($(this).index()).addClass('active');
        $('.below-left').removeClass('active').eq($(this).index()).addClass('active');
    });

    // open the popup with the video
    $('.infopack-trigger').magnificPopup({
        type: 'inline',
        midClick: true
    });

    // open the popup with the video
    $('.commercial-trigger').magnificPopup({
        type: 'inline',
        midClick: true
    });

    // open the popup with the video
    $('.delivery-trigger').magnificPopup({
        type: 'inline',
        midClick: true
    });

    // open the popup with the video
    $('.gallery-trigger').magnificPopup({
        type: 'inline',
        midClick: true
    });

    // pass tab id to hidden field on homepage form
    $('.tab').click(function () {
        var tabId = $(this).attr('id'); // Get the clicked tab's ID
        $('input[name="hidden-industry"]').val(tabId); // Update the hidden field with the tab's ID
    });

    // search menu
    var searchIcon = document.querySelector(".search-icon");
    var body = document.querySelector("body");
    var close = document.querySelector("button.close");

    if (searchIcon) {
        searchIcon.addEventListener("click", function () {
            body.classList.toggle("search-is-open");
        });
    }

    if (close) {
        close.addEventListener("click", function () {
            body.classList.remove("search-is-open");
        });
    }

    const noOfSliders = document.querySelectorAll('.gallery-trigger').length;
    const swiper = new Swiper('.swipergallery', {
        centeredSlides: true,
        autoHeight: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        runCallbacksOnInit: true,
        on: {
            slideChange: function () {
                // this.realIndex contains the current slide index
                // this.slides.length contains the total number of slides
                // query all divs with class 'inner' and update the text
                document.querySelectorAll('.numbering').forEach(el => el.innerHTML = `Slide ${this.realIndex + 1} of ${noOfSliders}`);
            },
            init: function () {
                // Also update on init in case the first slide is not 0
                document.querySelectorAll('.numbering').forEach(el => el.innerHTML = `Slide ${this.realIndex + 1} of ${noOfSliders}`);
            }
        },
    });

    // On gallery-trigger click
    $('.gallery-trigger').click(function () {
        // Get the index from data attribute
        const index = $(this).data('index');

        // Update initial slide
        swiper.slideTo(index, 0);
    });

    // Shipping Calculator
    $('#shipping-calculator-form').submit(function (e) {
        e.preventDefault();
        $('#shipping-results').slideUp();
        $('#shipping-info').slideUp();



        // get the endpoint
// Define your endpoint
        const url = '../../wp-json/easy-turf/v1/shipping-calculator';

        // get the fields
        // Get the form element
        const form = (document.querySelector('.cart'));
        const calculator_form = document.querySelector('#shipping-calculator-form');
        const variation_id = (calculator_form.querySelector('input[name="variation_id"]').value != '')? calculator_form.querySelector('input[name="variation_id"]').value : calculator_form.querySelector('input[name="product_id"]').value;
        const postcode = calculator_form.querySelector('input[name="postcode"]').value;
        const quantity = form.querySelector('input[name="quantity"]').value;
        const loading = document.querySelector('.loading');

        if(variation_id === ''){
            alert('Please select a variation');
            return false;
        }

        if(postcode === ''){
            alert('Please enter a postcode');
            return false;
        }

        if(quantity === ''){
            alert('Please enter a quantity');
            return false;
        }
        loading.classList.add('active');


// Collect all select fields in an object
        let formData = {};

        if(form.querySelectorAll('select')){
            form.querySelectorAll('select').forEach(select => {
                formData[select.name] = select.value;
            });
        }

        // get the shipping-calculator-form fields
        const shipform = document.querySelector('#shipping-calculator-form');
        let shipformdata = {};
        shipform.querySelectorAll('input').forEach(input => {
            shipformdata[input.name] = input.value;
        })

        let sendData = {
            'variations': formData,
            'shipform': shipformdata,
            'quantity': quantity,
        }

// Make the GET request
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendData)
        })
            .then(response => response.json())
            .then(data => {
                // Your logic to handle the response
                console.log('Success:', data);
                if(data.success === true){
                    let html = '<ul class="zone-prices">';
                    data.shipping_options.forEach((item) => {
                        html += `<li class="zone-price">
                            <span class="zone">${item.method_label}</span>
                            <span class="rate">${item.method_cost}</span>
                        </li>`;
                    });
                    html += '</ul>';
                    $('#shipping-results').html(html);
                }else{
                    let html = `<p class="error">${item.message}</p>`;
                }
                $('#shipping-results').slideDown();
                $('#shipping-info').slideDown();
                loading.classList.remove('active');
            })
            .catch((error) => {
                console.error('Error:', error);
                loading.classList.remove('active');
                alert('No shipping available. Please ensure that you have selected both a Roll Width and a Cut Length before trying again.');
            });
    });

    $('form.cart').on('found_variation', function(event, variation) {
        console.log("found_variation event triggered");
        // `variation` is a JavaScript object containing all the details of the found variation.
        var variation_id = variation.variation_id;
        const field = document.querySelector('#variation_id');
        field.value = variation_id;
        console.log("Variation ID: " + variation_id);
        // You can now use this variation ID as needed
    });



    // hamburger
    var hamburger = document.querySelector(".hamburger");
    var hamburgerClose = document.querySelector(".hamburger-close");
    var body = document.querySelector("body");

    if (hamburger) {
        hamburger.addEventListener("click", function () {
            hamburger.classList.toggle("is-active");
            body.classList.toggle("menu-is-open");
        });
    }

    if (hamburgerClose) {
        hamburgerClose.addEventListener("click", function () {
            hamburger.classList.remove("is-active");
            body.classList.remove("menu-is-open");
        });
    }

    // sub-menu items
    var hasChildren = document.querySelectorAll("#mobile-menu>nav ul li.menu-item-has-children>a");
    //on click of mobile menu item with children
    hasChildren.forEach(function (item) {
        item.addEventListener("click", function (e) {
            // prevent default only of the a item clicked
            e.preventDefault();
            //toggle the class of the parent li
            item.parentNode.classList.toggle("open");
        });
    });


    // adjust display price
        $('form.variations_form').on('found_variation', function(event, variation) {
            // Retrieve width and length from the variation object
            var width = variation.dimensions.width;
            var length = variation.dimensions.length;

            var price = variation.display_price;
            if(price < 0.01){
                price = parseFloat(document.querySelector('#price_per_m2').value)
                console.log(price);
            }

            // Calculate the square meters
            var square_meters = width * length;

            // Implement your pricing logic here
            // Assuming variation.display_price contains the base price per square meter
            var new_price = price * square_meters;
            console.log(new_price);

            // variation.price_html = '<span class="price">' + new_price.toFixed(2) + '</span>';
            if(new_price > 0){
                variation.price_html = '<span class="price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>' + new_price.toFixed(2) + '<span class="small"> (' + price.toFixed(2) + ' p/m2 inc. GST)</span></bdi></span></span>';
            }else{
                variation.price_html = '<span class="price"><span class="woocommerce-Price-amount amount"><bdi>Sorry, this combination is currently unavailable.</bdi></span></span>';

            }
        });
})

document.addEventListener("DOMContentLoaded", function() {
    var navbar = document.querySelector(".nav-buttons");
    var header = document.querySelector("#header");
    var sticky = navbar.offsetTop;
    var lastScrollTop = 0;  // Keep track of the last scroll position

    function toggleSticky() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
//             // Scrolling down
//             header.style.position = "absolute";
            if (scrollTop >= 71) {
                header.classList.add("sticky-down");
            }
        } else {
            // Scrolling up by at least 20px or reached the top of the page
            if (lastScrollTop - scrollTop > 20 || scrollTop === 0) {
                // header.style.position = "fixed";
                header.classList.remove("sticky-down");
            }
        }

        // Update last scroll position
        lastScrollTop = scrollTop;
    }

    // Call the function initially when the page loads
    toggleSticky();

    // Then call it again whenever the user scrolls
    window.addEventListener("scroll", toggleSticky);
});
